import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/login/Login";
import Index from "@/views/home/Index";
import sinak from "@/views/sinak";
import Customer from "@/views/customer/Customer";
import Supplier from "@/views/supplier/Supplier";
import product from "@/views/product/product";
import category from "@/views/product/category";
import ProductUnit from "@/views/product/ProductUnit";
import ProductLowStocks from "@/views/product/ProductLowStocks";
import user from "@/views/user/user";
import UserGroup from "@/views/user/UserGroup";
import BaseLine from "@/views/Statistic/BaseLine";
import ExpireList from "@/views/Statistic/ExpireList";
import InStatistic from "@/views/Statistic/InStatistic";
import CustomerCreditStatistic from "@/views/Statistic/CustomerCreditStatistic";
import InventorySummary from "@/views/Statistic/InventorySummary";
import OutStatistic from "@/views/Statistic/OutStatistic";
import ExpenditureCategoryStatistic from "@/views/Statistic/ExpenditureCategoryStatistic";
import CheckInStatistic from "@/views/Statistic/CheckInStatistic";
import InOutStatistic from "@/views/Statistic/InOutStatistic";
import AlwaysStatistic from "@/views/Statistic/AlwaysStatistic";
import TransactionsStatistic from "@/views/Statistic/TransactionsStatistic";
import InProduct from "@/views/inOut/InProduct";
import OutProduct from "@/views/inOut/OutProduct";
import inProductPre from "@/views/productList/inProductPre";
import outProductPre from "@/views/productList/outProductPre";

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: '登录',
    component: Login
  },
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/index',
    name: 'index',
    component: Index,
    children:[{
      path:'/customer',
      name:'customer',
      component:Customer
    },
      {
        path:'/supplier',
        name:'supplier',
        component:Supplier
      },
      {
        path:'/product',
        name:'product',
        component:product
      },
      {
        path:'/ProductUnit',
        name:'ProductUnit',
        component:ProductUnit
      },
      {
        path:'/category',
        name:'category',
        component:category
      },
      {
        path:'/ProductLowStocks',
        name:'ProductLowStocks',
        component:ProductLowStocks
      },
      {
        path:'/user',
        name:'user',
        component:user
      },
      {
        path:'/UserGroup',
        name:'UserGroup',
        component:UserGroup
      },{
        path:'/BaseLine',
        name:'BaseLine',
        component:BaseLine
      },{
        path:'/ExpireList',
        name:'ExpireList',
        component:ExpireList
      },{
        path:'/InStatistic',
        name:'InStatistic',
        component:InStatistic
      },{
        path:'/InventorySummary',
        name:'InventorySummary',
        component:InventorySummary
      },{
        path:'/CustomerCreditStatistic',
        name:'CustomerCreditStatistic',
        component:CustomerCreditStatistic
      },{
        path:'/OutStatistic',
        name:'OutStatistic',
        component:OutStatistic
      },{
        path:'/ExpenditureCategoryStatistic',
        name:'ExpenditureCategoryStatistic',
        component:ExpenditureCategoryStatistic
      },{
        path:'/CheckInStatistic',
        name:'CheckInStatistic',
        component:CheckInStatistic
      },{
        path:'/InOutStatistic',
        name:'InOutStatistic',
        component:InOutStatistic
      },{
        path:'/AlwaysStatistic',
        name:'AlwaysStatistic',
        component:AlwaysStatistic
      },{
        path:'/TransactionsStatistic',
        name:'TransactionsStatistic',
        component:TransactionsStatistic
      },{
        path:'/InProduct',
        name:'InProduct',
        component:InProduct
      },{
        path:'/OutProduct',
        name:'OutProduct',
        component:OutProduct
      },{
        path:'/inProductPre',
        name:'inProductPre',
        component:inProductPre
      },{
        path:'/outProductPre',
        name:'outProductPre',
        component:outProductPre
      }]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
