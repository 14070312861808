module.exports = {
    system:'系统提示',
    system_statistic:'系统已经限制显示最近30天的数据，筛选时间时只能选择最近30天的数据',
    system_three_one:'系统已经限制显示最近60天的数据，筛选时间时只能选择最近60天的数据',
    system_product:'下方选择数据即可，支持多个商品入库',
    out_system_product:'下方选择数据即可，支持多个商品出库',
    confrim_button:'确 定',
    cancel_button:'取 消',
    logout:'退出系统',
    loading:'加载中',
    system_create:'添加数据',
    out_system_create:'商品出库',
    system_edit:'编辑数据',
    system_shows:'查看数据',
    system_bohui:'驳回原因',
    system_delete:'此操作将永久删除该数据, 是否继续?',
    system_success_confrim:'是否继续操作？审核以后不能驳回！',
    system_success_in_product:'确定取消吗？当前已经选中商品了，继续确定的话选中好的商品会被清空！',
    system_require:'必填项不能为空',
    system_show:'查看',
    system_in_product:'商品入库',
    system_out_product:'商品出库',
    system_suppl:'审核',
    system__in_require:'入库的商品请选择',
    system__out_require:'出库的商品请选择',

    payment_mode:'0.0元',
    xiao_one:'出库的数量不足',

    system_select:'当前选中',

    system_empty_count:'请输入数量哦！数量不能空或不能为0',
    system_empty_count_out:'请输入数量哦！数量不能空或不能为0',

    loadings:'加载中..',
    request_title : '网络连接失败,请稍后重试！',
    request_title_image : '网络连接失败,请刷新页面重试或连接网络再重试！',

    empty:'无数据',
    empty_detail:'暂无详情信息',
    charts_empty:'暂无数据',
    select:'请选择数据',

    content:'请输入驳回的原因',


    have:'有',
    not_have:'没有',


    state_ok:'开启',
    state_fail:'关闭',

    create_submit:'添加',
    export_submit:'导出',

    shenhe_submit:'审核',
    in_mini_submit:'商品出货',
    bohui_submit:'驳回',
    download_submit:'下载图片',

    close_submit:'关闭窗口',
    printer:'打印',

    distron_content:'基本信息',
    detail_content:'详情信息',

    login:{
        title:'napis仓库管理系统',
        mobile_placeholder:'请输入手机号码',
        password_placeholder:'请输入密码',
        login_button : '登录'
    },
    lang_change:{
        title:'ئۇيغۇرچە',
    },
    modal_alert: {
        logout_content: '确定退出系统吗？如果想退出请按确定退出！操作取消点击上方×。',
    },
    menu_name:{
        customer_title:'客户管理',
        supplier_title:'供应商管理',

        product_label:'商品',
        product_title:'商品管理',
        product_chart_title:'商品轮播图',
        product_category_title:'商品分类',
        product_unit:'商品单位',
        product_price:'商品价格',
        product_low_stocks:'商品低存货量',

        user_label:'用户',
        user_title:'用户',
        user_group:'用户组',


        statistic_label:'统计',
        base_line:'库存不足统计',
        expire_list:'过期货品',
        in_statistic:'入库统计',
        inventory_summary:'库存汇总',
        customer_credit_statistic:'赊账统计',
        out_statistic:'出库统计',
        expenditure_category_statistic:'支出分类统计',
        check_in_statistic:'签到统计',
        in_out_statistic:'总资产统计',
        always_statistic:'盈亏统计',
        transactions_statistic:'总交易统计',

        in_put_product:'入出库管理',
        in_product:'入库',
        out_product:'出库',

        product_list:'商品订单管理',
        in_product_list:'入库管理',
        out_product_list:'出库管理'
    },
    search:{
        search_placeholder:'请输入内容搜索'
    },
    customer_table:{
        shop_name:'商店名称',

        name:'名称',

        type:'名称',
        out_money:'支出金额',

        product_name:'商品名称',
        warehouse_name:'仓库名',
        supplier_name:'供应商',
        supplier_phone:'供应商手机号',

        image:'商品图片',   //这块暂时定为统一
        image_el:'图片',   //这块暂时定为统一
        product_no:'商品编号',

        product_date:'生产日期',

        money_dan:'单价',

        category:'分类',
        unit:'商品单位',
        price:'价格',
        company:'公司',
        case_in_num:'数量',
        net_content:'净含量',
        validity:'保质期',
        images:'有关商品图片',

        share_wechat:'分享',

        qrCode:'生成二维码',


        insert_product:'商品添加',
        out_insert_product:'商品出库',

        wechat_mini_product:'小程序下单',
        normal_product:'正常下单',
        sub_warehouse_product:'分仓库下单',

        //in
        number_no:'订单编号',
        in_type_name:'入货类型',
        from_warehouse_name:'供应商',
        time:'时间',

        // out
        out_type_name:'出货类别',
        to_warehouse_name:'客户',
        transport_name:'出货形式',

        identifier:'标记',
        description:'描述',



        name_ug:'维语昵称',
        name_zh:'中文昵称',
        contact:'联系人',
        phone:'手机号',
        created_at:'创建时间',
        state:'状态',

        discription:'提示',


        group_name:'用户组',


        total_money:'总金额',

        //----------
        in_total : '入库数量',
        in_money: '入库总金额',
        out_total: '出库数量',
        out_money: '出库总金额',

        total_credit : '总赊账',
        repayment : '应还款',
        credit : '已还款',
        //--------




        count:'箱子',
        case:'个',

        weight:'排序',

        operation:'操作',

        edit_submit:'编辑',
        delete_submit:'删除',
        show_submit:'查看',

        create_shop_name:'请输入商店名称',
        create_name_ug:'请输入维语昵称',
        create_name_zh:'请输入汉语昵称',
        create_contact:'请输入联系人',
        create_phone:'请输入手机号',
        create_price:'请输入价格',
        create_state:'请选择状态',
        create_address:'请输入地址',

        create_is_edit_price:'修改价格权限是否有',

        create_number_no:'请输入订单编号',
        create_time:'请输入时间',

        create_reject:'请输入驳回原因',


        create_product_no:'请输入商品编号',
        create_case_in_num:'请输入数量',
        create_validity:'请输入保质期',
        create_net_content:'请输入净含量',

        create_weight:'请输入排序',


        create_count:'请输入箱子数量',

        m_price:'价格',



        empty_category:'请选择商店分类',
        empty_images:'上传有关商店的图片',

        xls_table_header:'客户管理导出信息',
        avatar_upload : '上传头像',

        empty_avatar_upload : '请上传头像',

        middle_price : '成本价格',


        avatar_stop:'当前限制选择 1 个文件，如果想删除重新上传，请把鼠标挪动图片区域然后点击叉图标删除图片，再次上传！',

        /* 审核 */
        wait_for_review : '等待审核',
        checked : '已审核',
        rejected:'已驳回',
        det:'全部',
        create_image:'请上传图片',
        // create_warehouse_id:'请选择公司',
        // create_company_id:'请选择公司',
    },
    xls_header:{
        supplier_xls_title:'供应商导出信息'
    },
    quick:{
        today:'今天',
        last_week :'最近一周',
        last_month :'最近一个月',
        last_three_months :'最近三个月',
        last_six_months :'最近六个月',
        to:'至',

        detail:'详情',

        weekPlaceholders:'请选择时间',

        start_placeholder:'开始日期',
        end_placeholder:'结束日期',
    },
    checkin_date:{
        work_shift:'签到时间',
        check_in_time_value:'打卡上班时间',
        type_value:'打卡类型',
        address:'签到位置',
        distance:'距离',
        date:'日期',
    }
}