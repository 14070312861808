import { render, staticRenderFns } from "./inProductPre.vue?vue&type=template&id=8f066440&scoped=true&"
import script from "./inProductPre.vue?vue&type=script&lang=js&"
export * from "./inProductPre.vue?vue&type=script&lang=js&"
import style0 from "./inProductPre.vue?vue&type=style&index=0&id=8f066440&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f066440",
  null
  
)

export default component.exports