<template>
    <div>
        <el-card shadow="hover">
            <div slot="header" class="clearfix">
                <span style="font-weight: bold;font-size: 20px">{{$t('menu_name.expenditure_category_statistic')}}</span>
            </div>
            <Row :gutter="10">
                <Col :span="24">
                    <DatePicker
                            type="month"
                            :value="value2"
                            :placeholder="$t('quick.weekPlaceholders')"
                            style="width: 200px"
                            size="large"
                            @on-change="dateTime"></DatePicker>
<!--                    <el-date-picker-->
<!--                            style="width: 35%"-->
<!--                            v-model="value2"-->
<!--                            type="date"-->
<!--                            align="right"-->
<!--                            :format="$lang == 2 ? 'yyyy 年 MM 月' : 'yyyy يىلى MM ئاي'"-->
<!--                            value-format="yyyy-MM"-->
<!--                            @change="dateTime">-->
<!--                    </el-date-picker>-->
                    <el-divider></el-divider>
                </Col>
                <Col :span="12">
                    <div style="text-align: center">

                        <el-table
                                :data="tableData.slice((currentPage-1)*pagesize,currentPage*pagesize)"
                                :header-cell-style="{'text-align':'center'}"
                                :cell-style="{'text-align':'center'}"
                                max-height="600"
                                style="width: 100%"
                                v-loading="tableLoading">
                            <el-table-column
                                    prop="id"
                                    type="index"
                                    label="ID">
                            </el-table-column>
                            <el-table-column
                                    prop="name"
                                    :label="$t('customer_table.name')">
                            </el-table-column>
                            <el-table-column
                                    prop="sum"
                                    :label="$t('customer_table.total_money')">
                            </el-table-column>
                            <el-table-column
                                    :label="$t('customer_table.operation')"
                                    width="250">
                                <template slot-scope="scope">
                                    <Row style="text-align: center;justify-items: center;margin: 0 auto">
                                        <Col :span="24">
                                            <el-button icon="el-icon-notebook-2" @click="handleShow(scope.row)" type="primary" size="small">
                                                {{$t("quick.detail")}}
                                            </el-button>
                                        </Col>
                                    </Row>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div style="text-align: center;margin-top: 30px;">
                            <el-pagination
                                    background
                                    layout="prev, pager, next"
                                    :total="total"
                                    @current-change="current_change">
                            </el-pagination>
                        </div>
                    </div>

                </Col>
                <Col :span="12">

                    <div v-if="chartData.categories == ''">
                        <el-empty :description="$t('charts_empty')"></el-empty>
                    </div>
                    <div v-else>
                        <div class="charts-box">
                            <ms-uchart
                                    type="mount"
                                    :opts="opts"
                                    :chartData="chartData"
                                    :ontap="false"
                            />
                        </div>
                    </div>



                </Col>
            </Row>
        </el-card>

    </div>
</template>

<script>
    export default {
        name: "ExpenditureCategoryStatistic",
        data() {
            return {

                pickerOptions: {
                    shortcuts: [{
                        text: this.$i18n.t('quick.today'),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', [start, end]);
                        }
                    },{
                        text: this.$i18n.t('quick.last_week'),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: this.$i18n.t('quick.last_month'),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    },{
                        text: this.$i18n.t('quick.last_three_months'),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    },{
                        text: this.$i18n.t('quick.last_six_months'),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },

                value2: '',

                tableLoading:true,

                tableData: [],

                total:0,
                pagesize:10,
                currentPage:1,

                chartData: {},
                opts: {
                    // color: ["#3590ff","#00aeff","#3590ff","#00aeff","#3590ff","#3590ff","#3590ff","#3590ff","#3590ff"],
                    padding: [15,15,0,5],
                    enableScroll: false,
                    legend: {},
                    errorShow:true,
                    xAxis: {
                        disableGrid: true
                    },
                    yAxis: {
                        data: [
                            {
                                min: 0
                            }
                        ]
                    },
                    extra: {
                        column: {
                            type: "group",
                            width: 30,
                            activeBgColor: "#000000",
                            activeBgOpacity: 0.08,
                            linearType: "custom",
                            seriesGap: 5,
                            linearOpacity: 0.5,
                            barBorderCircle: true,
                            customColor: [
                                "#FA7D8D",
                                "#EB88E2"
                            ]
                        },
                        mount: {
                            type: "bar",
                            widthRatio: 0.8
                        },
                    },
                },
                date:''
            }
        },
        mounted() {
            this.getServerData();
        },
        methods: {
            getServerData() {
                this.$http.get('statistic/cmsExpenditureCategoryStatistic')
                    .then((res)=>{
                        this.chartData = res.data.chartList
                        this.tableData = res.data.statisticList
                        this.date = res.data.date
                        this.total = res.count == undefined ? 0 : res.count
                    });
            },
            current_change(page){
                this.currentPage = page;
            },
            dateTime(e){
                this.$http.get('statistic/cmsExpenditureCategoryStatistic',{
                    params:{
                        date:e
                    }
                }).then((res)=>{
                    this.chartData = res.data.chartList
                    this.tableData = res.data.statisticList
                    this.date = res.data.date
                    this.total = res.count == undefined ? 0 : res.count
                });
            },
            handleShow(e){
                let category_id = e.categor_id
                this.$http.get('statistic/cmsExpenditureCategoryList',{
                    params:{
                        category_id:category_id,
                        date:this.date
                    }
                }).then((res)=>{
                    this.chartData = res.data.chartList
                    // this.tableData = res.data.statisticList
                });
            }
        }
    }
</script>

<style scoped>
    .charts-box {
        width: 100%;
        height: 600px;
    }
</style>