<template>
    <div>
        <el-card shadow="hover">
            <div slot="header" class="clearfix">
                <span style="font-weight: bold;font-size: 20px">{{$t('menu_name.out_statistic')}}</span>
            </div>
            <Row :gutter="10">
                <Col :span="24">
                    <el-alert
                            :title="$t('system')"
                            type="info"
                            show-icon
                            :description="$t('system_statistic')"
                            :closable="false">
                    </el-alert>
                </Col>
                <Col :span="24" style="margin-top: 1%">
                    <el-date-picker
                            style="width: 35%"
                            v-model="value2"
                            type="daterange"
                            align="right"
                            unlink-panels
                            :range-separator="$t('quick.to')"
                            :start-placeholder="$t('quick.start_placeholder')"
                            :end-placeholder="$t('quick.end_placeholder')"
                            :picker-options="pickerOptions"
                            :format="$lang == 2 ? 'yyyy 年 MM 月 dd 日' : 'yyyy يىلى MM ئاينىڭ dd كۈنى'"
                            value-format="yyyy-MM-dd"
                            @change="dateTime">
                    </el-date-picker>
                    <el-divider></el-divider>
                </Col>
                <Col :span="12">
                    <div style="text-align: center">

                        <el-table
                                :data="tableData.slice((currentPage-1)*pagesize,currentPage*pagesize)"
                                :header-cell-style="{'text-align':'center'}"
                                :cell-style="{'text-align':'center'}"
                                max-height="600"
                                style="width: 100%"
                                v-loading="tableLoading">
                            <el-table-column
                                    prop="id"
                                    type="index"
                                    label="ID">
                            </el-table-column>
                            <el-table-column
                                    prop="name"
                                    :label="$t('customer_table.name')">
                            </el-table-column>
                            <el-table-column
                                    prop="product_no"
                                    :label="$t('customer_table.product_no')">
                            </el-table-column>
                            <el-table-column
                                    prop="total"
                                    :label="$t('customer_table.total_money')">
                                <template slot-scope="scope">
                                    ￥{{scope.row.total}}
                                </template>
                            </el-table-column>
                        </el-table>
                        <div style="text-align: center;margin-top: 30px;">
                            <el-pagination
                                    background
                                    layout="prev, pager, next"
                                    :total="total"
                                    @current-change="current_change">
                            </el-pagination>
                        </div>
                    </div>

                </Col>
                <Col :span="12">

                    <div v-if="chartData.categories == ''">
                        <el-empty :description="$t('charts_empty')"></el-empty>
                    </div>
                    <div v-else>
                        <div class="charts-box">
                            <ms-uchart
                                    type="column"
                                    :opts="opts"
                                    :chartData="chartData"
                                    :ontap="false"
                            />
                        </div>
                    </div>



                </Col>
            </Row>
        </el-card>

    </div>
</template>

<script>
    export default {
        name: "OutStatistic",
        data() {
            return {

                pickerOptions: {
                    disabledDate(time) {
                        let curDate = new Date().getTime()

                        let three = 30 * 24 * 3600 * 1000 // 修改30就是天数

                        let threeMonths = curDate - three

                        return time.getTime() > Date.now() || time.getTime() < threeMonths
                    },
                    shortcuts: [{
                        text: this.$i18n.t('quick.today'),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', [start, end]);
                        }
                    },{
                        text: this.$i18n.t('quick.last_week'),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: this.$i18n.t('quick.last_month'),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },

                value2: '',

                tableLoading:true,

                tableData: [],

                total:0,
                pagesize:10,
                currentPage:1,

                chartData: {},
                opts: {
                    color: ["#3590ff","#00aeff","#3590ff","#00aeff","#3590ff","#3590ff","#3590ff","#3590ff","#3590ff"],
                    padding: [15,15,0,5],
                    enableScroll: false,
                    legend: {},
                    errorShow:true,
                    xAxis: {
                        disableGrid: true
                    },
                    yAxis: {
                        data: [
                            {
                                min: 0
                            }
                        ]
                    },
                    extra: {
                        column: {
                            type: "group",
                            width: 30,
                            activeBgColor: "#000000",
                            activeBgOpacity: 0.08,
                            // linearType: "custom",
                            seriesGap: 5,
                            // linearOpacity: 0.5,
                            barBorderCircle: true,
                        },
                    }
                },
            }
        },
        mounted() {
            this.getServerData();
        },
        methods: {
            getServerData() {
                this.$http.get('statistic/cmsOnStatistic')
                    .then((res)=>{
                        this.chartData = res.data.chartList
                        this.tableData = res.data.statisticList
                        this.total = res.count == undefined ? 0 : res.count
                    });
            },
            current_change(page){
                this.currentPage = page;
            },
            dateTime(e){
                this.$http.get('statistic/cmsOnStatistic',{
                    params:{
                        date:e
                    }
                }).then((res)=>{
                    this.chartData = res.data.chartList
                    this.tableData = res.data.statisticList
                    this.total = res.count == undefined ? 0 : res.count
                });
            }
        }
    }
</script>

<style scoped>
    .charts-box {
        width: 100%;
        height: 600px;
    }
</style>