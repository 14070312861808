<template>
    <div :style="$lang == 2 ? {direction:'ltr'} : {direction:'rtl'}">
        <Card>
            <p slot="title">{{$t('menu_name.user_group')}}</p>

            <div style="padding: 10px">

                <el-table
                        :data="tableData.slice((currentPage-1)*pagesize,currentPage*pagesize)"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}"
                        max-height="750"
                        style="width: 100%"
                        v-loading="tableLoading">
                    <el-table-column
                            prop="id"
                            type="index"
                            label="ID">
                    </el-table-column>
                    <el-table-column
                            prop="name_ug"
                            :label="$t('customer_table.name_ug')">
                    </el-table-column>
                    <el-table-column
                            prop="name_zh"
                            :label="$t('customer_table.name_zh')">
                    </el-table-column>
                    <el-table-column
                            prop="identifier"
                            :label="$t('customer_table.identifier')">
                        <template slot-scope="scope">
                            <el-tag type="success">{{scope.row.identifier}}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="description"
                            show-overflow-tooltip
                            :label="$t('customer_table.description')">
                    </el-table-column>
                    <el-table-column
                            prop="state"
                            :label="$t('customer_table.state')">
                        <template slot-scope="scope">
                            <div v-if="scope.row.state == $t('state_ok')">
                                <el-tag>{{scope.row.state}}</el-tag>
                            </div>
                            <div v-else>
                                <el-tag type="danger">{{scope.row.state}}</el-tag>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="created_at"
                            :label="$t('customer_table.created_at')">
                    </el-table-column>
                </el-table>

                <div style="text-align: center;margin-top: 30px;">
                    <el-pagination
                            background
                            layout="prev, pager, next"
                            :total="total"
                            @current-change="current_change">
                    </el-pagination>
                </div>

            </div>
        </Card>


    </div>
</template>

<script>
    export default {
        name: "UserGroup",
        data(){
            return{
                tableLoading:true,
                tableData: [],

                dialogVisible:false,

                categorys:[],


                total:0,
                pagesize:10,
                currentPage:1,



                //type  判断时新增还是编辑
                type:0,
                customerForm:{
                    name_ug:'',
                    name_zh:'',
                    weight:'',
                    state:1,
                },
                customerRules:{
                    name_ug:[
                        {required:true,message:this.$i18n.t("customer_table.create_name_ug"),trigger:'blur'}
                    ],
                    name_zh:[
                        {required:true,message:this.$i18n.t("customer_table.create_name_zh"),trigger:'blur'}
                    ],
                    weight:[
                        {required:true,message:this.$i18n.t("customer_table.create_weight"),trigger:'blur'}
                    ],
                    state: [
                        {required:true,message:this.$i18n.t("customer_table.create_state"),trigger:'blur'}
                    ],

                },

                jsonFile:{
                    ID:'id',
                    维语昵称:'name_ug',
                    汉语昵称:'name_zh',
                    排序:'weight',
                    状态:'state',
                    创建时间:'created_at',
                },
                jsonFileUy:{
                    ID:'id',
                    'ئۇيغۇرچە ئىسمى':'name_ug',
                    'خەنزۇچە ئىسمى':'name_zh',
                    'تەرتىپ':'weight',
                    'ھالىتى':'state',
                    'قېتىلغان ۋاقتى':'created_at',
                }


            }
        },
        mounted() {
            this.show();
        },
        methods:{
            show(){
                this.$http.get('cms_user/userGroupList',{
                    params:{
                        keyword:'',
                    }
                }).then((res)=>{
                    this.tableData = res.data
                    this.total = res.count
                })
            },
            current_change(page){
                this.currentPage = page;
            },
        },
    }
</script>

<style scoped>
    /deep/.el-dialog{
        border-radius: 10px;
    }
    /deep/ .el-upload--picture-card .upload_avatar{
        border-radius: 120px;
    }
</style>