<template>
    <div>
        <el-upload
                :action="$baseUrl + action"
                :list-type="listType"
                :on-success="handleAvatarSuccess"
                :multiple="multiple"
                accept="image/jpeg,image/jpg,image/png"
                :headers="headers"
                :limit="limit"
                :file-list="fileList"
                :on-exceed="handleExceed"
                :on-change="upldchange"
                :before-upload="beforeUpload">
            <i v-show="type == true" class="el-icon-plus"></i>
            <slot name="isautoload"></slot>
        </el-upload>
    </div>
</template>

<script>
    import {Loading} from "element-ui";
    import axios from "axios";

    export default {
        name: "UploadImage",
        props:{
            action:{
                type:String
            },
            multiple:{
                default:true
            },
            type:{
              default:false
            },
            listType:{
                default:'picture-card'
            },
            limit:{
                default:25
            },
            fileList:{
                type:Array,
                default:[]
            },
        },
        data() {
            return {
                dialogImageUrl: '',
                dialogVisible: false,
                headers:{
                    "Authorization" : `Bearer ${localStorage.getItem('token')}`
                }
            }
        },
        methods :{
            handleAvatarSuccess(res, file) {
                this.imageUrl = URL.createObjectURL(file.raw);

            },
            beforeUpload(file){
                // const isJPG = file.type === 'image/jpeg';
                // const isJpeg = file.type === 'image/jpg';
                // const isPng = file.type === 'image/png';

                const isLt2M = file.size / 1024 / 1024 < 1.5;
                // if (!isLt2M){
                    // let reader = new FileReader();
                    // let img = new Image();
                    // let maxSize = 300 * 1024   //400kb
                    // reader.readAsDataURL(file);
                    // reader.onload = (e) => {
                    //     img.src = e.target.result;
                    //     let data = null;
                    //     img.onload = () => {
                    //         let canvas = document.createElement('canvas');
                    //         let context = canvas.getContext('2d');
                    //         let width = img.width;
                    //         let height = img.height;
                    //         if (width > height) {
                    //             if (width > maxSize) {
                    //                 height = Math.round(height *= maxSize / width);
                    //                 width = maxSize;
                    //             }
                    //         } else {
                    //             if (height > maxSize) {
                    //                 width = Math.round(width *= maxSize / height);
                    //                 height = maxSize;
                    //             }
                    //         }
                    //         canvas.width = width;
                    //         canvas.height = height;
                    //         context.clearRect(0, 0, width, height);
                    //         context.drawImage(img, 0, 0, width, height);
                    //         data = canvas.toDataURL('image/jpeg', 0.2);
                    //         let newFile = this.dataURLtoFile(data, file.name);
                    //         this.upload(newFile);
                    //         return false;
                    //     }
                    // }

                // }

                // if (!isLt2M){}
                //
                // if (!isPng) {
                //     this.$msMessage.error('格式错误/jpg!');
                // }

                // }else if (!isJpeg){
                //     this.$msMessage.error('格式错误/jpeg!');
                // }else if (!isPng){
                //     this.$msMessage.error('格式错误/png!');
                // }
                //
                if (!isLt2M) {
                    this.$msMessage.error('图片大小不能超过 1.5MB!');
                }
                return isLt2M;
            },
            dataURLtoFile(dataurl, filename) {
                let arr = dataurl.split(','),
                    mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]),
                    n = bstr.length,
                    u8arr = new Uint8Array(n);
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new File([u8arr], filename, {
                    type: mime
                });
            },
            upload(file){
                var loading = Loading.service({
                    lock:true,
                    text:this.$i18n.t('loadings'),
                    background:'rgba(10, 10, 10, 0.8)'
                })
                let formData = new FormData();
                formData.append("file", file);
                // 上传图片
                axios.post(this.$baseUrl + 'customer/cms_up_image',formData,{
                    headers:{
                        token:localStorage.getItem('token'),
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then((res)=>{
                        this.fileList.push({
                            name:'a.png',
                            url:this.$appUrl + res.data.image_url
                        });
                        this.$emit('images',res.data)
                        loading.close()
                    }).catch((err)=>{
                    console.log(err)
                })
            },
            upldchange(file, fileList){
                var loading = Loading.service({
                    lock:true,
                    text:this.$i18n.t('loadings'),
                    background:'rgba(10, 10, 10, 0.8)'
                })
                if (file.status == 'success'){
                    loading.close()
                    this.$emit('images',file.response)
                }else if (file.status == 'error'){
                    this.$msMessage.error(this.$i18n.t('request_title_image'))
                }
                loading.close()

            },
            handleExceed(files, fileList){
                this.$msMessage.warning(this.$i18n.t('customer_table.avatar_stop'));
            },
        }
    }
</script>

<style scoped>

</style>