<template>
    <div>
        <el-card shadow="hover">
            <div slot="header" class="clearfix">
                <span style="font-weight: bold;font-size: 20px">{{$t('menu_name.check_in_statistic')}}</span>
            </div>
            <div v-if="total !== 0">
                <Row :gutter="10">
                    <Col :span="10">
                        <div style="text-align: center">
                            <el-table
                                    :data="tableData.slice((currentPage-1)*pagesize,currentPage*pagesize)"
                                    :header-cell-style="{'text-align':'center'}"
                                    :cell-style="{'text-align':'center'}"
                                    highlight-current-row
                                    max-height="600"
                                    ref="clickTable"
                                    style="width: 100%"
                                    v-loading="tableLoading"
                                    @current-change="tableChange">
                                <el-table-column
                                        prop="id"
                                        type="index"
                                        label="ID">
                                </el-table-column>
                                <el-table-column
                                        prop="image"
                                        :label="$t('customer_table.image_el')">
                                    <template slot-scope="scope">
                                        <el-image :src="scope.row.image"
                                                  style="border-radius: 10px;width: 50px;height: 50px;cursor: pointer">
                                            <div slot="error" class="image-slot">
                                                <i class="el-icon-picture-outline"></i>
                                            </div>
                                        </el-image>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        prop="name"
                                        :label="$t('customer_table.name')">
                                </el-table-column>
                            </el-table>
                            <div style="text-align: center;margin-top: 30px;">
                                <el-pagination
                                        background
                                        layout="prev, pager, next"
                                        :total="total"
                                        @current-change="current_change">
                                </el-pagination>
                            </div>
                        </div>

                    </Col>
                    <Col :span="14">
                        <div class="charts-box">
                            <el-table
                                    :data="tableDatas.slice((currentPages-1)*pagesizes,currentPages*pagesizes)"
                                    :header-cell-style="{'text-align':'center'}"
                                    :cell-style="{'text-align':'center'}"
                                    max-height="600"
                                    style="width: 100%">
                                <el-table-column
                                        prop="id"
                                        type="index"
                                        label="ID">
                                </el-table-column>
                                <el-table-column
                                        prop="to_work_time"
                                        :label="$t('checkin_date.work_shift')">
                                </el-table-column>
                                <el-table-column
                                        prop="to_work_check_in_time"
                                        :label="$t('checkin_date.check_in_time_value')">
                                </el-table-column>
                                <el-table-column
                                        prop="is_away_check"
                                        :label="$t('checkin_date.type_value')">
                                </el-table-column>
                                <el-table-column
                                        prop="address"
                                        show-overflow-tooltip
                                        :label="$t('checkin_date.address')">
                                </el-table-column>
                                <el-table-column
                                        prop="distance"
                                        show-overflow-tooltip
                                        :label="$t('checkin_date.distance')">
                                </el-table-column>
                                <el-table-column
                                        prop="date"
                                        show-overflow-tooltip
                                        :label="$t('checkin_date.distance')">
                                </el-table-column>
                            </el-table>
                            <div style="text-align: center;margin-top: 30px;">
                                <el-pagination
                                        background
                                        layout="prev, pager, next"
                                        :total="totals"
                                        @current-change="current_changes">
                                </el-pagination>
                            </div>
                        </div>

                    </Col>
                </Row>
            </div>
            <div style="text-align: center" v-else>
                <el-empty :description="$t('charts_empty')"></el-empty>
            </div>

        </el-card>

    </div>
</template>

<script>
    export default {
        name: "CheckInStatistic",
        data() {
            return {
                tableLoading:true,

                tableData: [],

                tableDatas: [],

                srcList : [],

                total:0,
                pagesize:10,
                currentPage:1,

                totals:0,
                pagesizes:10,
                currentPages:1,

                map:null

            }
        },
        mounted() {
            this.getServerData();
        },
        methods: {
            getServerData() {
                this.$http.get('statistic/cmsCheckInUserList')
                    .then((res)=>{
                        // this.chartData = res.data.chartList
                        this.tableData = res.data.items
                        this.total = res.count == undefined ? 0 : res.count

                        if (this.total !== 0){
                           this.$nextTick(()=>{
                               this.$refs.clickTable.setCurrentRow(this.tableData[0])
                           })
                        }
                    });
            },
            current_change(page){
                this.currentPage = page;
            },
            current_changes(page){
                this.currentPages = page;
            },
            tableChange(e){
                var id = e.id
                this.$http.get("statistic/cmsCheckInStatistic",{
                    params:{
                        user_id:id
                    }
                }).then((res)=>{
                    this.tableDatas = res.data.items
                    this.totals = res.count == undefined ? 0 : res.count
                })
            },
        }
    }
</script>

<style scoped>
    .charts-box {
        width: 100%;
        height: 600px;
    }
</style>