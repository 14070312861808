<template>
    <div :style="$lang == 2 ? {direction:'ltr'} : {direction:'rtl'}">
        <Card>
            <p slot="title">{{$t('menu_name.product_low_stocks')}}</p>

            <Row :gutter="20">
<!--                <Col :span="8">-->
<!--                    <Input search enter-button :placeholder="$t('search.search_placeholder')" @on-search="search" size="large"/>-->
<!--                </Col>-->
                <Row :span="2">
                    <el-button icon="el-icon-plus" @click="create">{{$t('create_submit')}}</el-button>
                </Row>
                <Col :span="2">
                    <download-excel :header="$t('xls_header.supplier_xls_title')" class="export-excel-wrapper" :data="tableData" :fields="$lang == 2 ? jsonFile : jsonFileUy" name="商品单位.xls">
                        <el-button icon="el-icon-download">{{$t('export_submit')}}</el-button>
                    </download-excel>

                </Col>
            </Row>

            <div style="padding: 10px">

                <el-table
                        :data="tableData.slice((currentPage-1)*pagesize,currentPage*pagesize)"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}"
                        max-height="750"
                        style="width: 100%"
                        v-loading="tableLoading">
                    <el-table-column
                            prop="id"
                            type="index"
                            label="ID">
                    </el-table-column>
                    <el-table-column
                            prop="product"
                            :label="$t('customer_table.product_name')">
                    </el-table-column>
                    <el-table-column
                            prop="warehouse"
                            :label="$t('customer_table.warehouse_name')">
                    </el-table-column>
                    <el-table-column
                            prop="count_prifix"
                            :label="$t('customer_table.case_in_num')">
                    </el-table-column>
                    <el-table-column
                            prop="created_at"
                            :label="$t('customer_table.created_at')">
                    </el-table-column>
                    <el-table-column
                            :label="$t('customer_table.operation')"
                            width="250">
                        <template slot-scope="scope">
                            <Row style="text-align: center;justify-items: center;margin: 0 auto">
                                <Col :span="11">
                                    <el-button icon="el-icon-edit" @click="handleEdit(scope.row)" type="primary" size="small">
                                        {{$t("customer_table.edit_submit")}}
                                    </el-button>
                                </Col>
                                <Col :span="11">
                                    <el-button icon="el-icon-delete" @click="handleDelete(scope.row)" type="danger" size="small">
                                        {{$t("customer_table.delete_submit")}}
                                    </el-button>
                                </Col>
                            </Row>

                        </template>
                    </el-table-column>
                </el-table>

                <div style="text-align: center;margin-top: 30px;">
                    <el-pagination
                            background
                            layout="prev, pager, next"
                            :total="total"
                            @current-change="current_change">
                    </el-pagination>
                </div>

            </div>
        </Card>

        <div style="direction: ltr">
            <el-dialog
                    :title="type == 1 ? $t('system_create') : $t('system_edit')"
                    :visible.sync="dialogVisible"
                    width="70%"
                    :close-on-click-modal="false"
                    :before-close="handleClose">
                <Form ref="customerRef" :model="customerForm" :rules="customerRules" :style="$lang == 2 ? {direction:'ltr'} : {direction:'rtl'}">
                    <Row :gutter="24">

                        <Col :span="24">
                            <FormItem>
                                <el-select v-model="customerForm.product_id" :placeholder="$t('select')" style="width: 100%" :no-data-text="$t('empty')">
                                    <el-option
                                            v-for="item in products"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </FormItem>
                        </Col>

                        <Col :span="24">
                            <FormItem>
                                <el-select v-model="customerForm.warehouse_id" :placeholder="$t('select')" style="width: 100%" :no-data-text="$t('empty')">
                                    <el-option
                                            v-for="item in warehouse"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </FormItem>
                        </Col>
                        <Col :span="24">
                            <FormItem prop="count">
                                <Input v-model="customerForm.count" search :enter-button="$t('customer_table.count')" @on-enter="customerCreate('customerRef')" type="text" :placeholder="$t('customer_table.create_count')" size="large"></Input>
                            </FormItem>
                        </Col>
                    </Row>

                </Form>

                <div slot="footer" class="dialog-footer">
                    <el-button @click="handleClose">{{$t('cancel_button')}}</el-button>
                    <el-button type="primary" @click="customerCreate('customerRef')">{{$t('confrim_button')}}</el-button>
                </div>

            </el-dialog>
        </div>


    </div>
</template>

<script>
    import UploadImage from "@/components/UploadImage";
    export default {
        name: "ProductLowStocks",
        data(){
            return{

                tableLoading:true,

                tableData: [],

                dialogVisible:false,

                warehouse:[],
                products:[],

                customer_id:0,


                total:0,
                pagesize:10,
                currentPage:1,

                //type  判断时新增还是编辑
                type:0,
                customerForm:{
                    product_id:'',
                    warehouse_id:'',
                    count:'',
                },
                customerRules:{
                    count:[
                        {required:true,message:this.$i18n.t("customer_table.create_count"),trigger:'blur'}
                    ],
                    // name_zh:[
                    //     {required:true,message:this.$i18n.t("customer_table.create_name_zh"),trigger:'blur'}
                    // ],

                },

                jsonFile:{
                    ID:'id',
                    维语昵称:'product',
                    汉语昵称:'warehouse',
                    状态:'count',
                    创建时间:'created_at',
                },
                jsonFileUy:{
                    ID:'id',
                    'مەھسۇلات ئىسمى':'product',
                    'ئامبار ئىسمى':'warehouse',
                    'سانى':'count',
                    'قېتىلغان ۋاقتى':'created_at',
                }


            }
        },
        components:{UploadImage},
        mounted() {
            this.show();
        },
        methods:{
            search(e){
                //第一次请求获取列表
                this.$http.get('cms_api/listCmsProductBaseline',{
                    params:{
                        keyword:e,
                    }
                }).then((res)=>{
                    this.tableData = res.data
                    this.total = res.count
                })
            },
            show(){
                this.$http.get('cms_api/listCmsProductBaseline',{
                    params:{
                        keyword:'',
                    }
                }).then((res)=>{
                    this.tableData = res.data
                    this.total = res.count
                })
            },
            create(){
                this.type = 1; // 新增标识
                this.$http.get('warehouse/selectCount')
                    .then((res)=>{
                        this.warehouse = res.data.warehouse
                        this.products = res.data.unit
                    })

                this.dialogVisible = true;
            },
            handleClose(){
                this.$refs.customerRef.resetFields();
                this.dialogVisible = false;
                this.customerForm.warehouse_id = '';
                this.customerForm.product_id = '';
                this.customerForm.count = '';

            },
            current_change(page){
                this.currentPage = page;
            },
            handleEdit(row){
                this.type = 2
                this.$http.get('warehouse/selectCount')
                    .then((res)=>{
                        this.warehouse = res.data.warehouse
                        this.products = res.data.unit
                    })
                var editDate = row
                this.customerForm = {
                    product_id:editDate.product_id,
                    warehouse_id:editDate.warehouse_id,
                    count:editDate.count,
                }
                this.customer_id = editDate.id

                this.dialogVisible = true
            },
            handleDelete(row){
                var id  = row.id
                this.$msConMessage.confirm(this.$i18n.t('system_delete'),this.$i18n.t('system'),{
                    confirmButtonText:this.$i18n.t('confrim_button'),
                    cancelButtonText:this.$i18n.t('cancel_button'),
                    type:'warning',
                    closeOnClickModal:false
                }).then((res)=>{
                    this.$http.post('cms_api/deleteProductBaseline',{
                        id:id
                    }).then((res)=>{
                        this.show()
                    })
                }).catch(()=>{
                    return true;
                })
            },
            customerCreate(formLogin){
                var customerForm = this.customerForm;
                this.$refs[formLogin].validate((valid)=>{
                    if (valid){
                        if (this.type == 1){
                            if (customerForm.product_id == ''){
                                this.$msMessage.error(this.$i18n.t('system_require'))
                            }else if(customerForm.warehouse_id == ''){
                                this.$msMessage.error(this.$i18n.t('system_require'));
                            }else{
                                this.$http.post('cms_api/createProductBaseline',{
                                    product_id:customerForm.product_id,
                                    warehouse_id:customerForm.warehouse_id,
                                    count:customerForm.count,

                                }).then((res)=>{
                                    this.handleClose()
                                    this.dialogVisible = false;
                                    this.show()
                                })
                            }


                        }else{
                            if (customerForm.product_id == ''){
                                this.$msMessage.error(this.$i18n.t('system_require'))
                            }else if(customerForm.warehouse_id == ''){
                                this.$msMessage.error(this.$i18n.t('system_require'));
                            }else{
                                this.$http.post('cms_api/updateProductBaseline',{

                                    id:this.customer_id,
                                    product_id:customerForm.product_id,
                                    warehouse_id:customerForm.warehouse_id,
                                    count:customerForm.count,

                                }).then((res)=>{
                                    this.handleClose()
                                    this.dialogVisible = false;
                                    this.show()
                                })
                            }
                        }

                    }

                })
            },
        },
    }
</script>

<style scoped>
    /deep/.el-dialog{
        border-radius: 10px;
    }
    /deep/ .el-upload--picture-card .upload_avatar{
        border-radius: 120px;
    }
</style>