<template>
    <div :style="$lang == 2 ? {direction:'ltr'} : {direction:'rtl'}">
        <Card>
            <p slot="title">{{$t('menu_name.product_title')}}</p>

            <Row :gutter="20">
                <Col :span="8">
                    <Input search enter-button :placeholder="$t('search.search_placeholder')" @on-search="search" size="large"/>
                </Col>
                <Row :span="2">
                    <el-button icon="el-icon-plus" @click="create">{{$t('create_submit')}}</el-button>
                </Row>
                <Col :span="2">
                    <download-excel :header="$t('customer_table.xls_table_header')" class="export-excel-wrapper" :data="tableData" :fields="$lang == 2 ? jsonFile : jsonFileUy" name="商品管理.xls">
                        <el-button icon="el-icon-download">{{$t('export_submit')}}</el-button>
                    </download-excel>
                </Col>
            </Row>

            <div style="padding: 10px">

                <el-table
                        :data="tableData.slice((currentPage-1)*pagesize,currentPage*pagesize)"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}"
                        max-height="750"
                        style="width: 100%"
                        v-loading="tableLoading">
                    <el-table-column
                            prop="id"
                            type="index"
                            label="ID">
                    </el-table-column>
                    <el-table-column
                            prop="avatar"
                            :label="$t('customer_table.image_el')">
                        <template slot-scope="scope">
                            <el-image :src="$appUrl + scope.row.avatar"
                                      style="border-radius: 10px;width: 50px;height: 50px;cursor: pointer"
                                      :preview-src-list="srcList"
                                      @click="loadImage(scope.row.avatar)">
                                <div slot="error" class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </el-image>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="name_ug"
                            :label="$t('customer_table.name_ug')">
                    </el-table-column>
                    <el-table-column
                            prop="name_zh"
                            :label="$t('customer_table.name_zh')">
                    </el-table-column>
                    <el-table-column
                            prop="mobile"
                            :label="$t('customer_table.phone')">
                    </el-table-column>
                    <el-table-column
                            prop="groupName"
                            :label="$t('customer_table.group_name')">
                    </el-table-column>
                    <el-table-column
                            prop="state"
                            :label="$t('customer_table.state')">
                        <template slot-scope="scope">
                            <div v-if="scope.row.state == $t('state_ok')">
                                <el-tag>{{scope.row.state}}</el-tag>
                            </div>
                            <div v-else>
                                <el-tag type="danger">{{scope.row.state}}</el-tag>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('customer_table.operation')"
                            width="250">
                        <template slot-scope="scope">
                            <Row style="text-align: center;justify-items: center;margin: 0 auto" :gutter="3">
                                <Col :span="11">
                                    <el-button icon="el-icon-edit" @click="handleEdit(scope.row)" type="primary" size="small">
                                        {{$t("customer_table.edit_submit")}}
                                    </el-button>
                                </Col>
                                <Col :span="11">
                                    <el-button icon="el-icon-delete" @click="handleDelete(scope.row)" type="danger" size="small">
                                        {{$t("customer_table.delete_submit")}}
                                    </el-button>
                                </Col>
                            </Row>

                        </template>
                    </el-table-column>
                </el-table>

                <div style="text-align: center;margin-top: 30px;">
                    <el-pagination
                            background
                            layout="prev, pager, next"
                            :total="total"
                            @current-change="current_change">
                    </el-pagination>
                </div>

            </div>
        </Card>

        <div style="direction: ltr">
            <el-dialog
                    :title="type == 1 ? $t('system_create') : $t('system_edit')"
                    :visible.sync="dialogVisible"
                    width="70%"
                    :close-on-click-modal="false"
                    :before-close="handleClose">
                <Form ref="customerRef" :model="customerForm" :rules="customerRules" :style="$lang == 2 ? {direction:'ltr'} : {direction:'rtl'}">
                    <Row :gutter="20">
                        <Col :span="24">
                            <FormItem>
                                <upload-image :file-list="logo_avatars" action="customer/cms_up_image" :type="true"
                                              :limit="1" :multiple="false" @images="avatar"></upload-image>
                            </FormItem>
                        </Col>
                        <Col :span="12">
                            <FormItem prop="name_zh">
                                <Input v-model="customerForm.name_zh" @on-enter="customerCreate('customerRef')" type="text" :placeholder="$t('customer_table.create_name_zh')" size="large"></Input>
                            </FormItem>
                        </Col>
                        <Col :span="12">
                            <FormItem prop="name_ug">
                                <Input v-model="customerForm.name_ug" @on-enter="customerCreate('customerRef')" type="text" :placeholder="$t('customer_table.create_name_ug')" size="large"></Input>
                            </FormItem>
                        </Col>
                        <Col :span="12">
                            <FormItem prop="mobile">
                                <Input v-model="customerForm.mobile" @on-enter="customerCreate('customerRef')" type="text" :placeholder="$t('customer_table.create_phone')" size="large"></Input>
                            </FormItem>
                        </Col>
                        <Col :span="12">
                            <FormItem prop="p_company_id">
                                <el-select v-model="customerForm.company_id" :placeholder="$t('select')" style="width: 100%" :no-data-text="$t('empty')">
                                    <el-option
                                            v-for="item in companys"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </FormItem>
                        </Col>
                        <Col :span="12">
                            <FormItem prop="categoey_id">
                                <el-select v-model="customerForm.warehouse_id" :placeholder="$t('select')" style="width: 100%" :no-data-text="$t('empty')">
                                    <el-option
                                            v-for="item in warehouses"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </FormItem>
                        </Col>
                        <Col :span="12">
                            <FormItem prop="unit_id">
                                <el-select v-model="customerForm.filiale_id" :placeholder="$t('select')" style="width: 100%" :no-data-text="$t('empty')">
                                    <el-option
                                            v-for="item in filiales"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </FormItem>
                        </Col>
                        <Col :span="12">
                            <FormItem prop="unit_id">
                                <el-select v-model="customerForm.group_id" :placeholder="$t('select')" style="width: 100%" :no-data-text="$t('empty')">
                                    <el-option
                                            v-for="item in groups"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </FormItem>
                        </Col>
                        <Col :span="12">
                            <FormItem prop="unit_id">
                                <el-select v-model="customerForm.is_edit_price" :placeholder="$t('customer_table.create_is_edit_price')" style="width: 100%" :no-data-text="$t('empty')">
                                    <el-option
                                            v-for="item in isEditPriceCate"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </FormItem>
                        </Col>

                        <Col :span="12">
                            <FormItem prop="state">
                                <el-radio-group v-model="customerForm.state" size="medium">
                                    <el-radio :label="1" border>{{$t('state_ok')}}</el-radio>
                                    <el-radio :label="0" border>{{$t('state_fail')}}</el-radio>
                                </el-radio-group>
                            </FormItem>
                        </Col>
                    </Row>

                </Form>

                <div slot="footer" class="dialog-footer">
                    <el-button @click="handleClose">{{$t('cancel_button')}}</el-button>
                    <el-button type="primary" @click="customerCreate('customerRef')">{{$t('confrim_button')}}</el-button>
                </div>

            </el-dialog>

        </div>

    </div>

</template>

<script>
    import UploadImage from "@/components/UploadImage";
    import UploadImageEdit from "@/components/UploadImageEdit";
    export default {
        name: "user",
        data(){
            return{
                direction: this.$lang == 1 ? 'rtl' : 'ltr',

                itemsShowImage:[],


                tableLoading:true,

                tableData: [],

                logo_avatars:[],

                srcList:[],

                dialogVisible:false,

                companys:[],
                warehouses:[],
                filiales:[],
                groups:[],

                customer_id:0,


                total:0,
                pagesize:10,
                currentPage:1,

                isEditPriceCate:[
                    {id:0,name:this.$i18n.t('not_have')},
                    {id:1,name:this.$i18n.t('have')},
                ],



                //type  判断时新增还是编辑
                type:0,
                customerForm:{
                    name_ug:'',
                    name_zh:'',
                    avatar:'',
                    mobile:'',
                    company_id:'',
                    warehouse_id:'',
                    filiale_id:'',
                    group_id:'',
                    is_edit_price:'',

                    state:1,

                },

                productView:'',

                customerRules:{
                    name_zh:[
                        {required:true,message:this.$i18n.t("customer_table.create_name_zh"),trigger:'blur'}
                    ],
                    name_ug:[
                        {required:true,message:this.$i18n.t("customer_table.create_name_ug"),trigger:'blur'}
                    ],
                    mobile:[
                        {required:true,message:this.$i18n.t("customer_table.create_phone"),trigger:'blur'}
                    ],

                },

                jsonFile:{
                    ID:'id',
                    维语昵称:'name_ug',
                    中文昵称:'name_zh',
                    手机号:'mobile_prefix',
                    用户组:'groupName',
                    状态:'state',
                },
                jsonFileUy:{
                    ID:'id',
                    'ئۇيغۇرچە ئىسمى':'name_ug',
                    'خەنزۇچە ئىسمى':'name_zh',
                    'تىلفۇن نۇمۇر':'mobile_prefix',
                    'ئەزا گۇرۇپپىسى':'groupName',
                    'ھالىتى':'state',
                }


            }
        },
        components:{UploadImage},
        mounted() {
            this.show();
        },
        methods:{
            search(e){
                //第一次请求获取列表
                this.$http.get('cms_user/userList',{
                    params:{
                        keyword:e,
                    }
                }).then((res)=>{
                    this.tableData = res.data.items
                    this.total = res.count
                })
            },
            show(){
                this.$http.get('cms_user/userList',{
                    params:{
                        keyword:'',
                    }
                }).then((res)=>{
                    this.tableData = res.data.items
                    this.total = res.count
                })
            },
            create(){
                this.type = 1; // 新增标识
                this.dialogVisible = true;
                this.$http.get('cms_api/PCompanySelectList')
                    .then((res)=>{
                        this.companys = res.data.company
                        this.warehouses = res.data.warehouse
                        this.filiales = res.data.filia
                        this.groups = res.data.usergroup
                    })

            },
            handleClose(){
                this.$refs.customerRef.resetFields();

                this.dialogVisible = false;
                this.customerForm.name_ug = '';
                this.customerForm.name_zh = '';
                this.customerForm.mobile = '';
                this.customerForm.company_id = '';
                this.customerForm.warehouse_id = '';
                this.customerForm.filiale_id = '';
                this.customerForm.group_id = '';

                this.logo_avatars = [];

            },
            current_change(page){
                this.currentPage = page;
            },
            handleEdit(row){
                this.type = 2
                var editDate = row

                this.$http.get('cms_api/PCompanySelectList')
                    .then((res)=>{
                        this.companys = res.data.company
                        this.warehouses = res.data.warehouse
                        this.filiales = res.data.filia
                        this.groups = res.data.usergroup
                    })


                this.customerForm = {
                    name_ug:editDate.name_ug,
                    name_zh:editDate.name_zh,
                    avatar: editDate.avatar,
                    mobile: editDate.mobile,
                    company_id:editDate.company_id,
                    warehouse_id:editDate.warehouse_id,
                    filiale_id:editDate.filiale_id,
                    group_id:editDate.group_id,
                    is_edit_price:editDate.is_edit_price,

                    state:editDate.states,
                }

                this.logo_avatars = [
                    {name:'avatar',url:editDate.image_prefix}
                ];

                this.customer_id = editDate.id

                this.dialogVisible = true
            },
            handleDelete(row){
                var id  = row.id
                this.$msConMessage.confirm(this.$i18n.t('system_delete'),this.$i18n.t('system'),{
                    confirmButtonText:this.$i18n.t('confrim_button'),
                    cancelButtonText:this.$i18n.t('cancel_button'),
                    type:'warning',
                    closeOnClickModal:false
                }).then((res)=>{
                    this.$http.post('cms_user/delete',{
                        id:id
                    }).then((res)=>{
                        this.show()
                    })
                }).catch(()=>{
                    return true;
                })
            },
            customerCreate(formLogin){

                var customerForm = this.customerForm;
                this.$refs[formLogin].validate((valid)=>{
                    if (valid){
                        if (customerForm.company_id == ''){
                            this.$msMessage.error(this.$i18n.t('system_require'))
                        }else if(customerForm.warehouse_id == ''){
                            this.$msMessage.error(this.$i18n.t('system_require'));
                        }else if (customerForm.filiale_id == '') {
                            this.$msMessage.error(this.$i18n.t('system_require'));
                        }else if (customerForm.filiale_id == '') {
                            this.$msMessage.error(this.$i18n.t('system_require'));
                        }else if (customerForm.avatar == '') {
                            this.$msMessage.error(this.$i18n.t('system_require'));
                        }else{
                            if (this.type == 1){
                                this.$http.post('cms_user/create',{
                                    name_ug:customerForm.name_ug,
                                    name_zh:customerForm.name_zh,
                                    avatar: customerForm.avatar,
                                    mobile:customerForm.mobile,
                                    company_id:customerForm.company_id,
                                    warehouse_id:customerForm.warehouse_id,
                                    filiale_id:customerForm.filiale_id,
                                    group_id:customerForm.group_id,
                                    is_edit_price:customerForm.is_edit_price,

                                    state:customerForm.state,

                                }).then((res)=>{
                                    this.handleClose()
                                    this.dialogVisible = false;
                                    this.show()
                                })

                            }else{

                                this.$http.post('cms_user/update',{
                                    id:this.customer_id,
                                    name_ug:customerForm.name_ug,
                                    name_zh:customerForm.name_zh,
                                    avatar: customerForm.avatar,
                                    mobile:customerForm.mobile,
                                    company_id:customerForm.company_id,
                                    warehouse_id:customerForm.warehouse_id,
                                    filiale_id:customerForm.filiale_id,
                                    group_id:customerForm.group_id,
                                    is_edit_price:customerForm.is_edit_price,


                                    state:customerForm.state,
                                }).then((res)=>{
                                    this.handleClose()
                                    this.dialogVisible = false;
                                    this.show()
                                })

                            }

                        }
                    }else{
                        return false;
                    }
                })

            },
            images(res){

                var images = res;

                this.customerForm.images.push(images.image_url)

            },
            avatar(res){
                this.customerForm.avatar = res.image_url
            },
            loadImage(res){
                this.srcList = [this.$appUrl + res]
            },

        },
    }
</script>

<style scoped>
    /deep/.el-dialog{
        border-radius: 10px;
    }
    /deep/ .el-upload--picture-card .upload_avatar{
        border-radius: 120px;
    }
</style>