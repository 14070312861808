<template>
    <div>
        <el-descriptions class="margin-top" :title="$t('distron_content')" :column="3" border
                         :content-style="$lang == 2 ? {textAlign:'left'} : {textAlign:'right'}"
                         :label-style="$lang == 2 ? {textAlign:'left'} : {textAlign:'right'}">
            <el-descriptions-item>
                <template slot="label">
                    {{$t('customer_table.warehouse_name')}}
                </template>
                {{data.warehouse_name}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    {{$t('customer_table.supplier_name')}}
                </template>
                {{data.supplier_name}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    {{$t('customer_table.supplier_phone')}}
                </template>
                <template v-if="data.supplier_tel">
                    {{data.supplier_tel}}
                </template>
                <template v-else>
                    {{$t('empty')}}
                </template>
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    {{$t('menu_name.product_category_title')}}
                </template>
                {{data.type_name}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    {{$t('customer_table.number_no')}}
                </template>
                {{data.number}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    {{$t('customer_table.out_money')}}
                </template>
                ￥ {{data.money}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    {{$t('customer_table.created_at')}}
                </template>
                {{data.created_at}}
            </el-descriptions-item>
        </el-descriptions>
    </div>
</template>

<script>
    export default {
        name: "InProductPreDescrip",
        props:{
            data:{
                required:true
            }
        }
    }
</script>

<style scoped>

</style>