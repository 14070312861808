<template>
    <div>

<!--        <el-card shadow="hover">-->
<!--            <div slot="header" class="clearfix">-->
<!--                <span style="font-weight: bold;font-size: 20px">{{$t('menu_name.transactions_statistic')}}</span>-->
<!--            </div>-->
<!--            <Row :gutter="10">-->
<!--                <Col :span="12">-->
<!--                    <div style="text-align: center">-->
<!--                        <el-table-->
<!--                                :data="tableData.slice((currentPage-1)*pagesize,currentPage*pagesize)"-->
<!--                                :header-cell-style="{'text-align':'center'}"-->
<!--                                :cell-style="{'text-align':'center'}"-->
<!--                                max-height="600"-->
<!--                                size="small"-->
<!--                                style="width: 100%"-->
<!--                                v-loading="tableLoading">-->
<!--                            <el-table-column-->
<!--                                    prop="id"-->
<!--                                    type="index"-->
<!--                                    label="ID">-->
<!--                            </el-table-column>-->
<!--                            <el-table-column-->
<!--                                    prop="name"-->
<!--                                    :label="$t('customer_table.name')">-->
<!--                            </el-table-column>-->
<!--                            <el-table-column-->
<!--                                    prop="product_no"-->
<!--                                    :label="$t('customer_table.product_no')">-->
<!--                            </el-table-column>-->
<!--                            <el-table-column-->
<!--                                    prop="count"-->
<!--                                    :label="$t('customer_table.case_in_num')">-->
<!--                            </el-table-column>-->
<!--                            <el-table-column-->
<!--                                    prop="discription"-->
<!--                                    :label="$t('customer_table.discription')">-->
<!--                                <template slot-scope="scope">-->
<!--                                    <el-tag type="danger">{{scope.row.discription}}</el-tag>-->
<!--                                </template>-->
<!--                            </el-table-column>-->
<!--                        </el-table>-->
<!--                        <div style="text-align: center;margin-top: 30px;">-->
<!--                            <el-pagination-->
<!--                                    background-->
<!--                                    layout="prev, pager, next"-->
<!--                                    :total="total"-->
<!--                                    @current-change="current_change">-->
<!--                            </el-pagination>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                </Col>-->
<!--                <Col :span="12">-->
<!--                    <div class="charts-box">-->
<!--                        <ms-uchart-->
<!--                                type="mount"-->
<!--                                :opts="opts"-->
<!--                                :chartData="chartData"-->
<!--                                :ontap="false"-->
<!--                        />-->
<!--                    </div>-->

<!--                </Col>-->
<!--            </Row>-->
<!--        </el-card>-->
        <Row>
            <Col :span="8" v-for="(item,index) in tableData" :key="index" style="padding: 5px">
                <el-card class="box-card" style="border-radius: 12px">
                    <div slot="header" class="clearfix">
                        <span style="font-size: 20px">{{item.name}}</span>
                    </div>
                    <div  class="text item" v-if="item.infos.length > 0">
                        <div v-for="(iitem,iindex) in item.infos" :key="iindex" style="font-size: 18px">
                            <div :style="$lang == 2 ? {direction:'ltr'} : {direction:'rtl'}">{{iitem.key}}</div>
                            <div :style="$lang == 2 ? {direction:'rtl'} : {direction:'ltr'}">￥{{iitem.value}}</div>
                        </div>
                    </div>
                    <div v-else>
                        <el-empty :description="$t('charts_empty')" :image-size="138"></el-empty>
                    </div>
                </el-card>
            </Col>
        </Row>


    </div>
</template>

<script>
    export default {
        name: "TransactionsStatistic",
        data() {
            return {
                tableLoading:true,

                tableData: [],

                total:0,
                pagesize:10,
                currentPage:1,

                chartData: {},
                opts: {
                    // color: ["#FAC858","#EE6666","#FAC858","#EE6666","#73C0DE","#3CA272","#FC8452","#9A60B4","#ea7ccc"],
                    padding: [15,15,0,5],
                    enableScroll: false,
                    legend: {},
                    name:'asd',
                    xAxis: {
                        disableGrid: true
                    },
                    yAxis: {
                        data: [
                            {
                                min: 0
                            }
                        ]
                    },
                    extra: {
                        column: {
                            type: "group",
                            width: 30,
                            activeBgColor: "#000000",
                            activeBgOpacity: 0.08,
                            linearType: "custom",
                            seriesGap: 5,
                            linearOpacity: 0.5,
                            barBorderCircle: true,
                            customColor: [
                                "#FA7D8D",
                                "#EB88E2"
                            ]
                        },
                        mount: {
                            type: "bar",
                            widthRatio: 0.8
                        },
                    }
                },
            }
        },
        mounted() {
            this.getServerData();
        },
        methods: {
            getServerData() {
                this.$http.get('statistic/transactionsStatistic')
                    .then((res)=>{
                        this.tableData = res.data.item
                    });
            },
            current_change(page){
                this.currentPage = page;
            },
        }
    }
</script>

<style scoped>
    .charts-box {
        width: 100%;
        height: 600px;
    }
</style>