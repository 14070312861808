<template>
    <div :style="$lang == 2 ? {direction:'ltr'} : {direction:'rtl'}">
        <Card>
            <p slot="title">{{$t('menu_name.supplier_title')}}</p>

            <Row :gutter="20">
                <Col :span="8">
                    <Input search enter-button :placeholder="$t('search.search_placeholder')" @on-search="search" size="large"/>
                </Col>
                <Row :span="2">
                    <el-button icon="el-icon-plus" @click="create">{{$t('create_submit')}}</el-button>
                </Row>
                <Col :span="2">
                    <download-excel :header="$t('xls_header.supplier_xls_title')" class="export-excel-wrapper" :data="tableData" :fields="$lang == 2 ? jsonFile : jsonFileUy" name="供应商.xls">
                        <el-button icon="el-icon-download">{{$t('export_submit')}}</el-button>
                    </download-excel>

                </Col>
            </Row>

            <div style="padding: 10px">

                <el-table
                        :data="tableData.slice((currentPage-1)*pagesize,currentPage*pagesize)"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}"
                        max-height="750"
                        style="width: 100%"
                        v-loading="tableLoading">
                    <el-table-column
                            prop="id"
                            type="index"
                            label="ID">
                    </el-table-column>
                    <el-table-column
                            prop="name_ug"
                            :label="$t('customer_table.name_ug')">
                    </el-table-column>
                    <el-table-column
                            prop="name_zh"
                            :label="$t('customer_table.name_zh')">
                    </el-table-column>
                    <el-table-column
                            prop="contact"
                            :label="$t('customer_table.contact')">
                    </el-table-column>
                    <el-table-column
                            prop="phone"
                            :label="$t('customer_table.phone')">
                    </el-table-column>
                    <el-table-column
                            prop="state"
                            :label="$t('customer_table.state')">
                        <template slot-scope="scope">
                            <div v-if="scope.row.state == $t('state_ok')">
                                <el-tag>{{scope.row.state}}</el-tag>
                            </div>
                            <div v-else>
                                <el-tag type="danger">{{scope.row.state}}</el-tag>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="created_at"
                            :label="$t('customer_table.created_at')">
                    </el-table-column>
                    <el-table-column
                            :label="$t('customer_table.operation')"
                            width="250">
                        <template slot-scope="scope">
                            <Row style="text-align: center;justify-items: center;margin: 0 auto">
                                <Col :span="11">
                                    <el-button icon="el-icon-edit" @click="handleEdit(scope.row)" type="primary" size="small">
                                        {{$t("customer_table.edit_submit")}}
                                    </el-button>
                                </Col>
                                <Col :span="11">
                                    <el-button icon="el-icon-delete" @click="handleDelete(scope.row)" type="danger" size="small">
                                        {{$t("customer_table.delete_submit")}}
                                    </el-button>
                                </Col>
                            </Row>

                        </template>
                    </el-table-column>
                </el-table>

                <div style="text-align: center;margin-top: 30px;">
                    <el-pagination
                            background
                            layout="prev, pager, next"
                            :total="total"
                            @current-change="current_change">
                    </el-pagination>
                </div>

            </div>
        </Card>

        <div style="direction: ltr">
            <el-dialog
                    :title="type == 1 ? $t('system_create') : $t('system_edit')"
                    :visible.sync="dialogVisible"
                    width="70%"
                    :close-on-click-modal="false"
                    :before-close="handleClose">
                <Form ref="customerRef" :model="customerForm" :rules="customerRules" :style="$lang == 2 ? {direction:'ltr'} : {direction:'rtl'}">
                    <Row :gutter="24">

                        <Col :span="24">
                            <FormItem prop="name_ug">
                                <Input v-model="customerForm.name_ug" @on-enter="customerCreate('customerRef')" type="text" :placeholder="$t('customer_table.create_name_ug')" size="large"></Input>
                            </FormItem>
                        </Col>

                        <Col :span="24">
                            <FormItem prop="name_zh">
                                <Input v-model="customerForm.name_zh" @on-enter="customerCreate('customerRef')" type="text" :placeholder="$t('customer_table.create_name_zh')" size="large"></Input>
                            </FormItem>
                        </Col>

                        <Col :span="24">
                            <FormItem prop="contact">
                                <Input v-model="customerForm.contact" @on-enter="customerCreate('customerRef')" type="text" :placeholder="$t('customer_table.create_contact')" size="large"></Input>
                            </FormItem>
                        </Col>
                        <Col :span="24">
                            <FormItem prop="phone">
                                <Input v-model="customerForm.phone" @on-enter="customerCreate('customerRef')" type="text" :placeholder="$t('customer_table.create_phone')" size="large"></Input>
                            </FormItem>
                        </Col>
                        <Col :span="24">
                            <FormItem prop="state">
                                <el-radio-group v-model="customerForm.state" size="medium">
                                    <el-radio :label="1" border>{{$t('state_ok')}}</el-radio>
                                    <el-radio :label="0" border>{{$t('state_fail')}}</el-radio>
                                </el-radio-group>
                            </FormItem>
                        </Col>
                    </Row>




                </Form>

                <div slot="footer" class="dialog-footer">
                    <el-button @click="handleClose">{{$t('cancel_button')}}</el-button>
                    <el-button type="primary" @click="customerCreate('customerRef')">{{$t('confrim_button')}}</el-button>
                </div>

            </el-dialog>
        </div>


    </div>
</template>

<script>
    import UploadImage from "@/components/UploadImage";
    export default {
        name: "Supplier",
        data(){
            return{

                tableLoading:true,

                tableData: [],

                dialogVisible:false,

                categorys:[],

                logo_avatars:[],
                shop_image:[],

                customer_id:0,


                total:0,
                pagesize:10,
                currentPage:1,



                //type  判断时新增还是编辑
                type:0,
                customerForm:{
                    name_ug:'',
                    name_zh:'',
                    contact:'',
                    phone:'',
                    state:1,
                },
                customerRules:{
                    name_ug:[
                        {required:true,message:this.$i18n.t("customer_table.create_name_ug"),trigger:'blur'}
                    ],
                    name_zh:[
                        {required:true,message:this.$i18n.t("customer_table.create_name_zh"),trigger:'blur'}
                    ],
                    contact:[
                        {required:true,message:this.$i18n.t("customer_table.create_contact"),trigger:'blur'}
                    ],
                    phone:[
                        {required:true,message:this.$i18n.t("customer_table.create_phone"),trigger:'blur'}
                    ],
                    state: [
                        {required:true,message:this.$i18n.t("customer_table.create_state")}
                    ],

                },

                jsonFile:{
                    ID:'id',
                    维语昵称:'name_ug',
                    汉语昵称:'name_zh',
                    联系人:'contact',
                    手机号:'phone',
                    状态:'state',
                    创建时间:'created_at',
                },
                jsonFileUy:{
                    ID:'id',
                    'ئۇيغۇرچە ئىسمى':'name_ug',
                    'خەنزۇچە ئىسمى':'name_zh',
                    'ئالاقىلاشقۇچى':'contact',
                    'تىلفۇن نۇمۇر':'phone',
                    'ھالىتى':'state',
                    'قېتىلغان ۋاقتى':'created_at',
                }


            }
        },
        components:{UploadImage},
        mounted() {
            this.show();
        },
        methods:{
            search(e){
                //第一次请求获取列表
                this.$http.get('supplier/listCms',{
                    params:{
                        keyword:e,
                        limit:10
                    }
                }).then((res)=>{
                    this.tableData = res.data
                    this.total = res.count
                })
            },
            show(){
                this.$http.get('supplier/listCms',{
                    params:{
                        keyword:'',
                        limit:10
                    }
                }).then((res)=>{
                    this.tableData = res.data
                    this.total = res.count
                })
            },
            create(){
                this.type = 1; // 新增标识
                this.dialogVisible = true;
            },
            handleClose(){
                this.$refs.customerRef.resetFields();
                this.dialogVisible = false;
                this.customerForm.name_ug = '';
                this.customerForm.name_zh = '';
                this.customerForm.contact = '';
                this.customerForm.phone = '';





            },
            current_change(page){
                this.currentPage = page;
            },
            handleEdit(row){
                this.type = 2
                var editDate = row
                this.customerForm = {
                    name_ug:editDate.name_ug,
                    name_zh:editDate.name_zh,
                    contact:editDate.contact,
                    phone:editDate.phone,
                    state:editDate.states,
                }
                this.customer_id = editDate.id

                this.dialogVisible = true
            },
            handleDelete(row){
                var id  = row.id
                console.log(id)
                this.$msConMessage.confirm(this.$i18n.t('system_delete'),this.$i18n.t('system'),{
                    confirmButtonText:this.$i18n.t('confrim_button'),
                    cancelButtonText:this.$i18n.t('cancel_button'),
                    type:'warning',
                    closeOnClickModal:false
                }).then((res)=>{
                    this.$http.post('supplier/delete',{
                        id:id
                    }).then((res)=>{
                        this.show()
                    })
                }).catch(()=>{
                    return true;
                })
            },
            customerCreate(formLogin){
                var customerForm = this.customerForm;
                this.$refs[formLogin].validate((valid)=>{
                    if (valid){
                        if (this.type == 1){
                            this.$http.post('supplier/create',{
                                name_ug:customerForm.name_ug,
                                name_zh:customerForm.name_zh,
                                contact:customerForm.contact,
                                phone:customerForm.phone,
                                state:customerForm.state,

                            }).then((res)=>{
                                this.handleClose()
                                this.dialogVisible = false;
                                this.show()
                            })

                        }else{

                            this.$http.post('supplier/update',{
                                id:this.customer_id,
                                name_ug:customerForm.name_ug,
                                name_zh:customerForm.name_zh,
                                contact:customerForm.contact,
                                phone:customerForm.phone,
                                state:customerForm.state,
                            }).then((res)=>{
                                this.handleClose()
                                this.dialogVisible = false;
                                this.show()
                            })

                        }

                    }

                })
            },
        },
    }
</script>

<style scoped>
    /deep/.el-dialog{
        border-radius: 10px;
    }
    /deep/ .el-upload--picture-card .upload_avatar{
        border-radius: 120px;
    }
</style>