<template>
    <div>
            <div v-if="data.length <= 0">
                <el-empty :description="$t('empty_detail')" :image-size="150"></el-empty>
            </div>
            <div v-else>
                <div style="font-size: 16px;font-weight: 700;color: #303133">{{$t('detail_content')}}</div>
                <el-table
                        :data="data"
                        style="width: 100%"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}"
                        :row-class-name="tableRowClassName">
                    <el-table-column
                        label="ID"
                        type="index">
                    </el-table-column>
                    <el-table-column
                            prop="name"
                            :label="$t('customer_table.product_name')">
                    </el-table-column>
                    <el-table-column
                            prop="count"
                            :label="$t('customer_table.case_in_num')">
                    </el-table-column>
                    <el-table-column
                            prop="price"
                            :label="$t('customer_table.money_dan')">
                        <template slot-scope="scope">
                            ￥ {{scope.row.price}}
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="money"
                            :label="$t('customer_table.total_money')">
                        <template slot-scope="scope">
                            ￥ {{scope.row.money}}
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="product_date"
                            :label="$t('customer_table.product_date')">
                    </el-table-column>
                </el-table>
            </div>
    </div>
</template>

<script>
    export default {
        name: "MsDescriptions",
        props: {
            data: {
                type:Array,
                default: function () {
                    return []
                }
            }
        },
        methods:{
            tableRowClassName({row, rowIndex}) {
                if (rowIndex === 1) {
                    return 'warning-row';
                } else if (rowIndex === 3) {
                    return 'success-row';
                }
                return '';
            }
        }
    }
</script>

<style scoped>
    /deep/.el-table .warning-row {
        background: #fcf1e7;
    }

    /deep/.el-table .success-row {
        background: #f0f9eb;
    }
</style>