<template>
    <div>
        <Layout class="layout">
            <Header>
                <font :class="lang == 2 ? 'mws-title-ltr' : 'mws-title-rtl'">{{$t('login.title')}}</font>
                <Icon  @click.native="collapsedSider" :class="rotateIcon" :style="lang == 1 ? {marginTop: '20px',marginRight:'10px',float:'right',cursor: 'pointer'} : {margin: '0 20px',cursor: 'pointer'}" type="md-menu" size="24"></Icon>
                <div :class="lang == 2 ? 'avatar-ltr' : 'avatar-rtl'">
                    <Dropdown @on-click="logout">
                        <Avatar style="cursor: pointer" :src="avatar" size="large" />
                        <DropdownMenu slot="list">
                            <DropdownItem name="logoust">{{$t('logout')}}</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
                <i @click="full" :class="lang == 2 ? 'float-change-ltr rotateIcon' : 'float-change-rtl rotateIcon'" style="margin-top: 1%;margin-left: 10px;margin-right: 10px;line-height: 100%;font-size: 26px" v-show="!isFull" class="el-icon-full-screen"></i>
                <i @click="full" :class="lang == 2 ? 'float-change-ltr rotateIcon' : 'float-change-rtl rotateIcon'" style="margin-top: 1%;margin-left: 10px;margin-right: 10px;line-height: 100%;font-size: 26px" v-show="isFull" class="el-icon-rank"></i>
                <div :class="lang == 2 ? 'float-change-ltr' : 'float-change-rtl' " style="margin-right: 10px;margin-left: 10px" @click="langChange">{{$t('lang_change.title')}}</div>
            </Header>
            <Layout>
                <Sider v-show="lang == 2" ref="side1" hide-trigger collapsible :collapsed-width="78" v-model="isCollapsed" width="220px">
<!--                    此处时导航区域-->
                    <Menu :active-name="this.$route.path" @on-select="selectRoutePush" theme="dark" width="auto" :class="menuitemClasses" accordion>
                        <MenuItem :name="item.to" v-for="(item,index) in collapse" :key="index">
                            <Icon :custom="'iconfont ' + item.icon" :style="{color:item.style}" size="28"></Icon>
                            <span>{{item.title}}</span>
                        </MenuItem>

                        <Submenu :name="item.id" v-for="(item,index) in subCollapse" :key="index + '1'">
                            <template slot="title">
                                <Icon :custom="'iconfont ' + item.icon" :style="{color:item.style}" size="28"></Icon>
                                <span>{{item.title}}</span>
                            </template>
                            <MenuItem :name="items.to" v-for="(items,index) in item.route" :key="items.id + '1' ">
<!--                                <Icon :custom="'iconfont ' + items.icon" :style="{color:item.style}" size="24"></Icon>-->
                                <span>{{items.title}}</span>
                            </MenuItem>
                        </Submenu>
                    </Menu>

                </Sider>
                <Content :style="{margin: '20px', background: '#fff', minHeight: '220px',borderRadius:'12px'}">
                    <div :style="lang == 2 ? {color: '#2c3e50',padding: '10px'} : {direction: 'rtl',color: '#2c3e50',padding: '10px'}">
                        <router-view></router-view>
                    </div>
                </Content>
                <Sider v-show="lang == 1" ref="side1" hide-trigger collapsible :collapsed-width="78" v-model="isCollapsed" style="direction: rtl;" width="220px">

                    <Menu :active-name="this.$route.path" theme="dark" @on-select="selectRoutePush" width="auto" :class="menuitemClasses" accordion>
                        <MenuItem :name="item.to"  v-for="(item,index) in collapse" :key="index">
                            <Icon :custom="'iconfont ' + item.icon" :style="{color:item.style}" size="28"></Icon>
                            <span style="margin-right:4%">{{item.title}}</span>
                        </MenuItem>

                        <Submenu :name="item.id" v-for="(item,index) in subCollapse" :key="index + '1'">
                            <template slot="title">
                                <Icon :custom="'iconfont ' + item.icon" :style="{color:item.style}" size="28"></Icon>
                                <span style="margin-right:4%">{{item.title}}</span>
                            </template>
                            <MenuItem :name="items.to" v-for="(items,index) in item.route" :key="items.id + '1' ">
<!--                                <Icon :custom="'iconfont ' + items.icon" :style="{color:items.style}" size="24"></Icon>-->
                                <span>{{items.title}}</span>
                            </MenuItem>
                        </Submenu>
                    </Menu>

                </Sider>
            </Layout>
        </Layout>
    </div>
</template>

<script>
    import MenuList from "@/components/MenuList";
    import screenfull from 'screenfull'
    export default {
        name: "Index",

        data(){
            return {
                lang:localStorage.getItem('lang') == 'zh' ? 2 : 1,
                isCollapsed: false,
                // isCollapseds:false,

                avatar:'',

                isFull:false,

                collapse:[
                    {name:'customer',title:this.$i18n.t('menu_name.customer_title'),to:'/customer',icon:'icon-kehuguanli',style:''},
                    {name:'supplier',title:this.$i18n.t('menu_name.supplier_title'),to:'/supplier',icon:'icon-ziyuan',style:''}
                ],
                subCollapse:[
                    {id:1,name:'product',title:this.$i18n.t('menu_name.product_label'),icon:'icon-wodeshangpin',style:'',route:[
                            {id:1,name:'product',title:this.$i18n.t('menu_name.product_title'),to:'/product',icon:'ms-shangpinguanli1',style:''},
                            // {id:2,name:'chart',title:this.$i18n.t('menu_name.product_chart_title'),to:'/chart',icon:'md-baseball',style:''},
                            {id:3,name:'category',title:this.$i18n.t('menu_name.product_category_title'),to:'/category',icon:'ms-categorys',style:''},
                            {id:4,name:'product_unit',title:this.$i18n.t('menu_name.product_unit'),to:'/ProductUnit',icon:'ms-category',style:''},
                            // {id:5,name:'product_price',title:this.$i18n.t('menu_name.product_price'),to:'/ProductPrice',icon:'md-baseball',style:''},
                            {id:6,name:'product_low_stocks',title:this.$i18n.t('menu_name.product_low_stocks'),to:'/ProductLowStocks',style:'',icon:'ms-caigoushouhuodan'}
                            ]
                    }, {id:2,name:'user',title:this.$i18n.t('menu_name.user_label'),icon:'icon-yonghuming',style:'',route:[
                            {id:1,name:'user',title:this.$i18n.t('menu_name.user_title'),to:'/user',icon:'ms-yonghu',style:''},
                            {id:2,name:'user_group',title:this.$i18n.t('menu_name.user_group'),to:'/UserGroup',icon:'md-baseball',style:''},
                        ]
                    }, {id:3,name:'statistic',title:this.$i18n.t('menu_name.statistic_label'),icon:'icon-zonghetongji-',style:'',route:[
                            {id:1,name:'base_line',title:this.$i18n.t('menu_name.base_line'),to:'/BaseLine',icon:'ms-yonghu',style:''},
                            {id:2,name:'expire_list',title:this.$i18n.t('menu_name.expire_list'),to:'/ExpireList',icon:'md-baseball',style:''},
                            {id:3,name:'in_statistic',title:this.$i18n.t('menu_name.in_statistic'),to:'/InStatistic',icon:'md-baseball',style:''},
                            {id:4,name:'inventory_summary',title:this.$i18n.t('menu_name.inventory_summary'),to:'/InventorySummary',icon:'md-baseball',style:''},
                            {id:5,name:'customer_credit_statistic',title:this.$i18n.t('menu_name.customer_credit_statistic'),to:'/CustomerCreditStatistic',icon:'md-baseball',style:''},
                            {id:6,name:'out_statistic',title:this.$i18n.t('menu_name.out_statistic'),to:'/OutStatistic',icon:'md-baseball',style:''},
                            {id:7,name:'expenditure_category_statistic',title:this.$i18n.t('menu_name.expenditure_category_statistic'),to:'/ExpenditureCategoryStatistic',icon:'md-baseball',style:''},
                            {id:8,name:'check_in_statistic',title:this.$i18n.t('menu_name.check_in_statistic'),to:'/CheckInStatistic',icon:'md-baseball',style:''},
                            {id:9,name:'in_out_statistic',title:this.$i18n.t('menu_name.in_out_statistic'),to:'/InOutStatistic',icon:'md-baseball',style:''},
                            {id:10,name:'always_statistic',title:this.$i18n.t('menu_name.always_statistic'),to:'/AlwaysStatistic',icon:'md-baseball',style:''},
                            {id:11,name:'transactions_statistic',title:this.$i18n.t('menu_name.transactions_statistic'),to:'/TransactionsStatistic',icon:'md-baseball',style:''},
                        ]
                    }, {id:4,name:'inProduct',title:this.$i18n.t('menu_name.in_put_product'),icon:'icon-churukou',style:'',route:[
                            {id:1,name:'user',title:this.$i18n.t('menu_name.in_product'),to:'/InProduct',icon:'ms-yonghu',style:''},
                            {id:2,name:'user_group',title:this.$i18n.t('menu_name.out_product'),to:'/OutProduct',icon:'md-baseball',style:''},
                        ]
                    }, {id:5,name:'productList',title:this.$i18n.t('menu_name.product_list'),icon:'icon-dingdan-',style:'',route:[
                            {id:1,name:'user',title:this.$i18n.t('menu_name.in_product_list'),to:'/inProductPre',icon:'ms-yonghu',style:''},
                            {id:2,name:'user_group',title:this.$i18n.t('menu_name.out_product_list'),to:'/outProductPre',icon:'md-baseball',style:''},
                        ]
                    },
                ]
            }
        },
        components:{
            MenuList
        },
        computed: {
            rotateIcon () {
                return [
                    'menu-icon',
                    this.isCollapsed ? 'rotate-icon' : ''
                ];
            },
            menuitemClasses () {
                return [
                    'menu-item',
                    this.isCollapsed ? 'collapsed-menu' : ''
                ]
            }
        },

        mounted(){
            this.home();
            this.userInfo();
        },
        methods:{
            full(){
                screenfull.toggle();
                if (!screenfull.isEnabled){
                    this.$msMessage({
                        message:'مۇھىت قوللىمايدۇ',
                        type:'warning'
                    })
                }
                this.isFull = !this.isFull

            },
            home(){
                var token = localStorage.getItem('token');
                if (!token){
                    this.$router.push('/')
                }
                if (this.$route.name == 'index'){
                    this.$router.push('/customer')
                }

            },
            userInfo(){
                this.$http.get('user/info')
                    .then((res)=>{
                        this.avatar = res.data.face_image
                    })
            },
            logout(e){
                var that = this;
                var loading = that.$t('loading')

                this.$Modal.warning({
                    title:this.$t('system'),
                    content:this.$t('modal_alert.logout_content'),
                    closable:true,
                    okText:this.$t('confrim_button'),
                    onOk(){
                        that.$Message.loading(loading)
                        localStorage.removeItem('token')
                        localStorage.removeItem('access')
                        setTimeout(()=>{

                            location.reload()

                        },2000)
                    }
                })

            },
            langChange(){
                let isZh = localStorage.getItem('lang')
                    ? localStorage.getItem('lang')
                    : 'zh'
                if (isZh === 'zh') {
                    localStorage.setItem('lang', 'uy')
                } else {
                    localStorage.setItem('lang', 'zh')
                }
                location.reload()
            },
            collapsedSider () {
                this.$refs.side1.toggleCollapse();
            },
            selectRoutePush(res){
                this.$router.push(res)
            }
        }
    }
</script>

<style scoped>
    .layout{
        width: 100vw;
        height: 100vh;
        color: white;
        background: #f5f7f9;
    }
    /*.layout{*/
    /*    border: 1px solid #d7dde4;*/
    /*    background: #f5f7f9;*/
    /*    position: relative;*/
    /*    border-radius: 4px;*/
    /*    overflow: hidden;*/
    /*}*/
    /*.layout-logo{*/
    /*    width: 100px;*/
    /*    height: 30px;*/
    /*    background: #5b6270;*/
    /*    border-radius: 3px;*/
    /*    float: left;*/
    /*    position: relative;*/
    /*    top: 15px;*/
    /*    left: 20px;*/
    /*}*/
    /*.layout-nav{*/
    /*    width: 420px;*/
    /*    margin: 0 auto;*/
    /*    margin-right: 20px;*/
    /*}*/
    .layout-header-bar{
        background: #fff;
        box-shadow: 0 1px 1px rgba(0,0,0,.1);
    }
    .layout-logo-left{
        width: 90%;
        height: 30px;
        background: #5b6270;
        border-radius: 3px;
        margin: 15px auto;
    }
    .menu-icon{
        transition: all .3s;
    }
    .rotate-icon{
        transform: rotate(-90deg);
    }
    .menu-item span{
        display: inline-block;
        overflow: hidden;
        width: 120px;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: bottom;
        transition: width .2s ease .2s;
    }
    .menu-item i{
        transform: translateX(0px);
        transition: font-size .2s ease, transform .2s ease;
        vertical-align: middle;
        font-size: 16px;
    }
    .collapsed-menu span{
        width: 0px;
        transition: width .2s ease;
    }
    .collapsed-menu i{
        transform: translateX(5px);
        transition: font-size .2s ease .2s, transform .2s ease .2s;
        vertical-align: middle;
        font-size: 22px;
    }
    .mws-title-ltr{
        font-size: 17px;
    }
    .mws-title-rtl{
        float: right;
        font-size: 17px;
    }
    .float-change-ltr{
        float: right;
        font-size: 19px;
        cursor: pointer;
        font-weight: bold;
    }
    .float-change-rtl{
        float: left;
        font-size: 19px;
        cursor: pointer;
        font-weight: bold;
    }
    .show-icon-rtl{
        float: right;
    }

    .avatar-ltr{
        float: right;
        margin-left: 1%;
    }
    .avatar-rtl{
        float: left;
        margin-right: 1%;
    }
    /deep/ .ivu-icon{
        line-height: 20px;
    }
    /deep/ .ivu-menu-vertical .ivu-menu-submenu-title-icon{
        position: absolute;
        top: 50%;
        right: v-bind();
    }
</style>