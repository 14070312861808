<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
  export default {
    mounted() {
      document.title = this.$i18n.t('login.title')
    }
  }
</script>
<style>
  @font-face {
    font-family: uyHat;
    src: url("@/assets/fonts/UKIJTuT.ttf");
  }
  #app {
    width:100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'uyHat';
  }
  html,body{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'uyHat';
  }
  *{
    font-family: 'uyHat';
  }
  .el-image__inner{
    border-radius: 12px;
  }
</style>
