<template>
    <div>
        <div v-for="(index,key) in info" :key="key" style="font-size: 50px">
            {{index}}
        </div>
        <div>
            {{uid}}
        </div>
        <div>
            <Input v-model="content"></Input>
        </div>
        <div>
            <Input v-model="uids" placeholder="自写唯一id"></Input>
        </div>
        <div>
            <Button type="primary" @click="fasong">发送</Button>
        </div>
        <div>
            <Button type="primary" @click="saklax">保存唯一标识符</Button>
        </div>
        <div>
            <Button type="primary" @click="lianjie">连接</Button>
        </div>
        <div>
            <Button type="primary" @click="guanbi">关闭连接</Button>
        </div>
        <div>
            <Button type="primary" @click="chakan">查看client_id</Button>
        </div>



    </div>
</template>

<script>
    const wx = new WebSocket("ws://rmssocket.munber.club:1220");
    const uid = require('uuid');
    import axios from 'axios';
    export default {
        name: "sinak",
        data(){
            return {
                is:false,
                info:[],
                content:'',
                uid:'',
                uids:''
            }
        },
        mounted() {
            this.show();
        },
        methods:{
            show(){
                // console.log('连接websoket')
                var that = this
                wx.onopen = function () {
                    console.log('连接成功')
                    this.is = true;
                    that.$Message.success({
                        background:true,
                        duration:100,
                        content:'连接成功'
                    })
                    that.bindUid()
                }
                wx.onmessage = function (res) {
                    console.log(res.data)
                    let m = JSON.parse(res.data)
                    that.info.push(m)
                    sessionStorage.setItem('client_id',m.client_id)
                }
                wx.onclose = function () {
                    that.$Message.error({
                        background:true,
                        duration:20,
                        content:'连接已断开'
                    })
                }
            },
            message(res){
                console.log(res)
            },
            guanbi(){
                wx.close()
            },
            lianjie(){
                // this.show()

            },
            bindUid(){
                // console.log(uid.v1())
                axios.post('http://rmssocket.munber.club/index/bindUid',{
                    client_id:sessionStorage.getItem('client_id'),
                    uid:sessionStorage.getItem('uid')
                }).then((res)=>{
                    console.log(res)
                }).catch((res)=>{
                    console.log(res)
                })
            },
            fasong(){
                var content = this.content
                console.log(content)

            },
            chakan(){
                this.uid = sessionStorage.getItem('client_id') + '----' + sessionStorage.getItem('uid')
            },
            saklax(){
                sessionStorage.setItem('uid',this.uids)
            }
        },

    }
</script>

<style scoped>

</style>