<template>
    <div>
        <div class="bg-image">
            <div class="content-border">
                <div class="content-title">
                    {{$t("login.title")}}
                </div>
                <div class="form-contanair">
                    <Form ref="formLogin" :model="formLogin" :rules="formRule">
                        <FormItem prop="username">
                            <Input type="text" v-model="formLogin.username" @on-enter="login('formLogin')" :placeholder="$t('login.mobile_placeholder')" maxlength="11" size="large">
                                <Icon type="ios-person-outline" slot="prepend"></Icon>
                            </Input>
                        </FormItem>
                        <FormItem prop="password">
                            <Input type="password" v-model="formLogin.password" password @on-enter="login('formLogin')" :placeholder="$t('login.password_placeholder')" size="large">
                                <Icon type="ios-lock-outline" slot="prepend"></Icon>
                            </Input>
                        </FormItem>
                        <FormItem>
                            <Button type="primary" size="large" :loading="loading" @click="login('formLogin')" long>{{$t('login.login_button')}}</Button>
                        </FormItem>
                    </Form>
                </div>
            </div>

            <div class="number-center-image">
                <img :src="src">
            </div>

        </div>
    </div>
</template>

<script>
    import axios from "axios";
    export default {
        name: "Login.vue",
        data(){
           return {
               src:require('@/assets/logo.png'),

               loading:false,
               formLogin:{
                   username:'',
                   password:''
               },
               formRule:{
                   username: [
                       {required:true,message:this.$i18n.t("login.mobile_placeholder"),trigger:'blur'}
                   ],
                   password:[
                       {required:true,message:this.$i18n.t("login.password_placeholder"),trigger: 'blur'}
                   ]
               }

           }
        },
        mounted() {
            this.show()
        },
        methods:{
            show(){
                var token = localStorage.getItem('token');
                if (token){
                    this.$router.push('/customer')
                }

                // this.formRule.username =
            },
            login(formLogins){
                this.loading = true
                this.$refs[formLogins].validate((valid) => {
                    if (valid){

                        var mobile = this.formLogin.username;
                        var password = this.formLogin.password;
                        // this.$Message.success({
                        //     // background:true,
                        //     content:'已完成输入',
                        //     size: 'large',
                        //     duration:3
                        // })
                        var lang = localStorage.getItem('lang');

                        if (lang == 'zh'){
                            var langs = 2;
                        }else{
                            var langs = 1;
                        }
                        
                        axios.post(this.$baseUrl + "user/login",{
                            mobile:mobile,
                            password:password,
                            cms:1

                        },{headers:{
                            lang:langs
                            }}).then((res)=>{
                            if (res.data.status == 200){
                                this.$Message.success(res.data.msg)
                                localStorage.setItem('token',res.data.data.token);
                                if (localStorage.getItem('lang')) localStorage.removeItem('lang')
                                if (localStorage.getItem('lang') == 'zh'){
                                    localStorage.setItem('lang','zh')
                                }else if (localStorage.getItem('lang') == 'uy'){
                                    localStorage.setItem('lang','uy')
                                }else{
                                    localStorage.setItem('lang','zh')
                                }
                                window.location.href = '/index'
                            }else{
                                this.$Message.error(res.data.msg)
                            }
                            this.loading = false
                        }).catch((res)=>{
                            console.log(res)
                            this.loading = false
                        })

                    }else{
                        this.loading = false
                        return false;
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .bg-image {
        background:url("@/assets/image/archive-g737f026f0_1920.jpg") no-repeat center;
        background-size: cover;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        /*下方为变暗属性*/
        /*filter: brightness(0.9);*/
    }
    .text-none{
        font-size: 25px;
        color:#ffffff;
        font-style: oblique;
    }
    .content-border{
        width: 550px;
        height: 450px;
        background-color:#ffffff;
        box-shadow: 2px 4px 6px #ffffff;
        opacity: 0.9;
        border-radius: 12px;
        margin: 0 auto;
        margin-top: 100px;

        .content-title{
            font-size: 30px;
            padding-top: 90px;
            text-align: center;
            width: 98%;
        }
        
    }
    .number-center-image{
        position: absolute;
        top: 2.5%;
        left: 46%;

        img{
            width: 150px;
            height: 150px;
            border-radius: 160px;
            opacity: 0.9;
            border: 2px solid #42b983;
            box-shadow: 2px 4px 6px #ffffff;

        }
    }
    .form-contanair{
        width: 350px;
        text-align: center;
        margin: 5% auto;
    }




</style>